<template>
    <div style="position: relative">
        <b-loading :is-full-page="false" v-model="isLoading"> </b-loading>
        <ul v-if="perfiles.length !== 0">
            <li v-for="perfil in perfiles" :key="perfil.id">
                <a
                    class="box m-3"
                    :class="{ logged: perfil.loggeado }"
                    @click.prevent="
                        perfil.loggeado ? null : selectPerfil(perfil)
                    "
                >
                    <article class="media">
                        <div class="media-left">
                            <figure class="image">
                                <img
                                    :src="
                                        getCompleteUrl(
                                            perfil.trabajador.empresa
                                                .ruta_imagen
                                        )
                                    "
                                    :alt="
                                        perfil.trabajador.empresa.razon_social
                                    "
                                    style="width: 120px"
                                />
                            </figure>
                        </div>
                        <div class="media-content">
                            <div class="content">
                                <p class="is-size-6 is-size-7-mobile">
                                    <strong
                                        >Empresa:
                                        <span class="has-text-primary">{{
                                            perfil.trabajador.empresa
                                                .razon_social
                                        }}</span></strong
                                    >
                                </p>
                                <small
                                    >Perfil:
                                    <b>{{ perfil.descripcion }}</b></small
                                ><br />
                                <small
                                    >Estado:
                                    <b-tag
                                        :type="
                                            perfil.activo
                                                ? 'is-success'
                                                : 'is-danger'
                                        "
                                        >{{
                                            perfil.activo
                                                ? 'Activo'
                                                : 'No Activo'
                                        }}</b-tag
                                    ></small
                                >
                            </div>
                        </div>
                    </article>
                </a>
            </li>
        </ul>
        <div v-else class="has-text-centered">
            <p class="my-5 subtitle has-text-grey">
                No tiene perfiles asignados
            </p>
        </div>
    </div>
</template>

<script>
import { BASE_URL } from '@/config';

export default {
    name: 'Perfiles',
    data() {
        return {
            baseUrl: BASE_URL,
            isLoading: false,
        };
    },
    computed: {
        currentToken() {
            return this.$store.state.auth.token;
        },
        perfiles() {
            return this.$store.state.me.perfiles;
        },
    },
    mounted() {
        if (this.perfiles.length === 0) {
            this.fetchPerfiles();
        }
    },
    methods: {
        async fetchPerfiles() {
            this.isLoading = true;
            await this.$store.dispatch('me/getPerfiles');
            this.isLoading = false;
        },
        async selectPerfil(perfil) {
            this.isLoading = true;
            try {
                const data = {
                    perfil_id: perfil.id,
                    temporalToken: this.currentToken,
                };

                await this.$store.dispatch('auth/changePerfil', data);
                await this.$store.dispatch(
                    'ui/setisModalSeleccionarPerfil',
                    false
                );
                window.location.replace('/');
            } catch (error) {
                this.isLoading = false;
                this.message =
                    (error.response && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        },
        getCompleteUrl(url) {
            return `${this.baseUrl}/${url}`;
        },
    },
};
</script>

<style>
.logged {
    box-shadow: none !important;
    background-color: #f0f2f5;
}

.logged:hover {
    cursor: default;
}

.image {
    background: white;
}
</style>
