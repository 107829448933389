<template>
    <transition name="fade">
        <div class="modal is-active" v-if="showModal">
            <div class="modal-background" @click="closeModal"></div>
            <div class="modal-card">
                <section class="modal-card-body m-2">
                    <perfiles />
                </section>
            </div>
            <button
                class="modal-close is-large"
                aria-label="close"
                @click="closeModal"
            ></button>
        </div>
    </transition>
</template>

<script>
import Perfiles from '@/components/Login/Perfiles.vue';

export default {
    components: {
        Perfiles,
    },
    computed: {
        showModal() {
            return this.$store.state.ui.modals.seleccionarPerfil;
        },
    },
    methods: {
        closeModal() {
            this.$store.dispatch('ui/setisModalSeleccionarPerfil', false);
        },
    },
};
</script>

<style></style>
